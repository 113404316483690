

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class RadioV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    const radioInput = this.element.querySelector('.elRadioInput')
    if (this.element.dataset.triggerClickOnWrapper == 'true') {
      this.element.addEventListener('click', (e) => {
        if (e.target.closest('a')) return 
        e.preventDefault();
        this.enableRadio(radioInput)
      })
    }
  }

  enableRadio(radio) {
    const event = new Event('change');
    if (!radio.checked) radio.checked = true;
    radio.dispatchEvent(event);
  }



}

registerComponent('Radio/V1', RadioV1)
window["RadioV1"] = RadioV1

