

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckboxV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

checkbox: HtmlElement
  mount() {
    this.checkbox = this.element.querySelector('.elCheckboxInput')
    this.preventClickOnLabel = this.element.getAttribute('data-prevent-click-on-label')
    if (this.element.dataset.triggerClickOnWrapper == 'true') {
      this.element.addEventListener('click', (e) => {
        if (e.target.closest('a')) return 
        e.preventDefault();
        this.toggle()
      })
    } else if (this.preventClickOnLabel == 'true') {
      this.element.querySelector('.elCheckboxLabel').addEventListener('click', (e) => {
        if (e.target.closest('a')) return 
        e.preventDefault();
      })
      this.element.querySelector('.elCheckbox').addEventListener('click', (e) => {
        this.toggle()
      })
    } else {
      this.element.addEventListener('click', (evt) => {
        this.toggle()
      })
      this.checkbox.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
      })
    }

    if (this.useCheckboxIcon) {
      const fakeCheckbox = this.element.querySelector('.elCheckbox')
      fakeCheckbox.addEventListener('click', () => {
        this.toggle()
      })
    }
  }

  toggle() {
    this.setChecked(!this.checkbox.checked);
  }

  setChecked(checked) {
    const event = new Event('change');
    this.checkbox.checked = checked
    this.checkbox.dispatchEvent(event);
  }



}

registerComponent('Checkbox/V1', CheckboxV1)
window["CheckboxV1"] = CheckboxV1

